/*#282c34 #FFF3E7 #0A5786*/
/*#ff #39383B*/
/*#A39D92*/
/*#A7A88A*/

/*https://codepen.io/Christopherallen/pen/Clajw*/

body {
  background-color: #39383B;
}

a {
  color:  #39383B;
}

h1, h2 {
  color: #0A5786;
}

a:hover {
  color:  #0A5786;
  text-decoration-color: #8F3B21;
}

.App {
  color: #39383B;
  text-align: center;
}

.nav {
  background-color: #A7A88A;
  border: 2px solid #39383B;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.nav h2 {
  color:  #0A5786;
}

.nav a {
  color:  #0A5786;
  font-weight: bolder;
}

.reading-portion {
  background-color: #FFF3E7;
  border: 2px solid #39383B;
  margin: -2px auto 25px auto;
  min-height: 100vh;
  padding: 40px 20px;
  width: 70%;
}

.reading-portion.overflown {
  float: left;
  overflow: scroll;
  width:  100%;
}

.map-container {
  background-color: #000;
  border: 5px solid #39383B;
  height: 80%;
  margin: auto;
  width: 80%;
}

.overlay {
  left: 0;
  opacity: 0.5;
  position: absolute;
}

.collapseable {
  margin: 20px 0;
  text-align: left;
  border: 1px solid #39383B;
}

.hidden-list {
  border-top: 1px solid #39383B;
  overflow: scroll;
}

.hidden-list li {
  margin: 10px 0;
}

.hidden-list.alignment-list, .hidden-list.stat-list {
  padding: 10px;
}

table {
  border-collapse: collapse;
  font-size: 18px;
  margin: 25px auto;
  min-width: 750px;
}

table th,td {
  border: 1px solid #000;
  margin: 0;
  padding: 3px 8px;
}

table th, td:first-child {
  background-color: #A7A88A;
  color:  #39383B;
  font-weight: bold;
}

table td:first-child {
  background-color: #A7A88A;
  color:  #39383B;
}

.internal-panel {
  margin: auto;
  width: 90%;
}

li.class-li {
  background-position: right;
  background-repeat: no-repeat;
  margin-right: 30px;
  min-height:  315px;
}

.labeled {
  margin:  14px 0;
}

li.class-li .blurb {
  width: 70%;
}

li.class-li .labeled {
  width: 70%;
}

.char-image {
  max-height: 500px;
  max-width: 100%;
  overflow:  hidden;
}

.char-image .zoom-map {
  position: relative;
}

.char-image span {
  top: 216px;
  left: 115px;
  color: #F00;
  font-size: 35px;
  position: absolute;
  z-index: 5000;
}

.char-image-showImage {
  border: 1px solid #DDD;
  animation: fadein 1000ms;
  right: 0;
  max-height: 800px;
  min-height: 400px;
  max-width: 800px;
  min-width: 325px;
  margin:  auto;
  position: absolute;
  top: 0;
}

.half-panel {
  display: inline-block;
  vertical-align: top;
  width: 73%;
}

.most-a-panel {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

.info-card {
  border: 1px solid #DDD;
  float: right;
  margin: 20px 8px;
  position: relative;
  width: 91%;
}

.nav .mobile-header, .mobile-home-link {
  display: none;
}



.info-card h3 {
  border: 1px solid #39383B;
  width:  100%;
}

.nav .links {
  margin-bottom: 10px;
}

.nav .links a {
  margin: 8px;
  padding: 7px;
}


.index-listing {
  border:  1px solid #39383B;
  display: inline-block;
  margin: 2%;
  padding: 2%;
  text-align: left;
  width: 40%;
  vertical-align: top;
}

.index-listing img {
  max-height: 100px;
  max-width: 60px;
  margin-left: auto;
}

.index-listing .info-panel {
  display: inline-block;
  float: right;
  margin-left: 2%;
  width:  76%;
  vertical-align: top;
}

.index-listing h3 {
  border-bottom: 1px solid #39383B;
  padding-left: 4%;
}

.index-listing span {
  padding-left: 4%;
}

.divine-display table {
  text-align: left;
  margin-left: 0;
  width: 1500px;
}

.divisor {
  text-align: left;
  width: 100%;
}

.divisor h2 {
  border-bottom: 1px solid #39383B;
  padding: 5px 0 10px 0;
  text-align: center;
}

.side-panel {
  display: inline-block;
  vertical-align: top;
  width: 40%;
}

.left-label {
  display: inline-block;
  margin-left:  3%;
  text-align: left;
  width: 29%;
  vertical-align: top;
}

.right-blurb {
  display: inline-block;
  text-align: left;
  width: 68%;
  vertical-align: top;
}

.character-info {
  line-height: 23px;
  text-align: left;
}

.character-info h2,h3{
  border-bottom: 1px solid #39383B;
  padding-bottom: 10px;
}

.collapseable h2, h3 {
  cursor: pointer;
  border-bottom: 0px;
  text-align: center;
  padding-bottom: 0px;
}

.map-image {
  position: absolute;
}

.regional-marker {
  height: 100px !important;
  width: 100px !important;
}


.marker {
  background-color: #F22;
  border: 1.5px solid #000;
  border-radius: 200px;
  height: 15px;
  position: absolute;
  width: 15px;
  z-index: 999;
}

.marker.larger {
  height: 35px;
  width: 35px;
}

.marker span {
  background-color: #F22;
  border: 1px solid #000;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  color: #fff;
  font-size: 22px;
  left: 17px;
  padding: 4px 10px;
  position: relative;
  overflow: visible;
  top: -24px;
  white-space: nowrap;
}

.fair-isle-svg {
  position: absolute;
}

@media (max-width: 700px) {
  .blurb, .labeled {
    background-color: #FFF3E7CA;
  }
}

.login-box {
  text-align: right;
  width: 28%;
  margin: auto;
}

.auth-button-container {
  text-align: center;
}

.random-list {
  margin: 20px auto;
  width: 40%;
}

.random-button-container {
  text-align: center;
}

.random-list button {
  font-size: 20px;
  margin: auto;
  padding: 10px;
}

.character-info textarea {
  width: 100%;
  min-height: 150px;
}

.subcollapse {
  cursor: pointer;
  padding-left: 15px;
}

.stat-list {
  margin: auto;
  width: 100%;
}

.stat-block {
  display: flex;
  margin:  50px;
  text-align: center;
}

.stat-list .stat {
  border:  1px solid #333;
  border-radius: 5px;
  height: 99px;
  margin: 10px;
  /*padding: 8px 12px 0 12px;*/
  width: 100px;
}

.stat-list .stat .big-score {
  font-size: 35px
}

.stat-name {
  margin:  0;
  margin-bottom: 10px;
  padding:  0;
}

.stat-list .stat .stat-bonus {
  background-color: #FFF3E7;
  border:  1px solid #333;
  border-radius: 45px;
  font-size: 30px;
  margin: 25px;
  margin-top: 13px;
}

.selectors {
  display: flex;
}

.selectors {
  display: flex;
}

.array-selects, .race-selects {
  min-width: 30%;
}

.timeline-container {
  display: flex;
  flex-direction: column;
}

.timeline-container .timeline-event{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.timeline-container .timeline-event .col1 {
  align-self: center;
  flex: 1;
}

.timeline-container .timeline-event .col2 {
  flex: 3;
  text-align: left;
}

.timeline-container .timeline-event .col2.centered {
  text-align: center;
}

tr {
  counter-increment: rowNumber;
}

table:not(.regular) td:first-child::before {
  content: counter(rowNumber);
  min-width: 1em;
  margin-right: 0.5em;
}

.full-screen {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fog-of-war-box {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 140px;
  margin-right: 8px;
  margin-bottom: 10px;
  position: absolute;
  width: 142px;
}

.fog-of-war-box.cragmaw {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 134px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  width: 134px;
}

.fog-of-war-box.thunda {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 190px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  width: 190px;
}


.fog-of-war-box.neutralparty {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 102px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  width: 102px;
}

.fog-of-war-box.small-small-small {
  box-shadow: 0 0 5px 3px #333;
  display: inline-block;
  height: 17px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  width: 17px;
}

.fog-of-war-box.dungeon-alchemist {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 292px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  width: 292px;
}


.fog-of-war-box.smalled {
  box-shadow: 0 0 35px 12px #333;
  display: inline-block;
  height: 66px;
  margin-right: 2px;
  margin-bottom: 2px;
  position: absolute;
  width: 66px;
}


#measure::before{
  content: "";
  display: block;
  position: absolute;
  width: 1em; height: 1em;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  background: #DDD;
  border-radius: 100%;
}


.prima-inset-key {
  position: absolute;
  height: 825px;
  border: 40px solid #F00;
  z-index: 100;
  width: 595px;
}


.prima-map-key {
  border: 40px solid #F00;
  left: 77%;
  position: absolute;
  scale: 0.03;
  top: 675px;
  transform-origin: top left;
  z-index: 10;
}

@media (max-width: 1450px) {
  .prima-map-key {
    left: 75.6%;
  }
}

@media (max-width: 1050px) {
  .prima-map-key {
    left: 70.6%;
  }
}

@media (max-width: 700px) {
  .prima-map-key {
    left: 58%;
    top: 970px;
  }
}






.rarity-controls {
  display: inline-block;
  text-align: right;
  width: 45%;
}

.rarity-controls input{
  width: 50px;
}

.type-controls {
  display: inline-block;
  text-align: right;
  width: 45%;
}








.evil {
  color: #F00;
}

.good {
  color: #0C0;
}

.lawful {
  color: #07F;
}

.chaotic {
  color: #F0F;
}






/* PIE PIE PIE PIE PIE */

.pieContainer {
  height: 1050px;
  margin-left: 70px;
  margin-top: 50px;
  position: relative;
}

.pieBackground {
  position: absolute;
  width: 1050px;
  height: 1050px;
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  transform: rotate(11.25deg);
}

.pie {
  transition: all 1s;
  position: absolute;
  width: 1050px;
  height: 1050px;
  border-radius: 100%;
  clip: rect(0px, 525px, 1050px, 0px);
}

.fun-pie {
  transition: all 1s;
  position: absolute;
  width: 1050px;
  height: 1050px;
  border-radius: 100%;
  clip: rect(0px, 525px, 1050px, 0px);
  transform: rotate(4deg);
  background-color: #606;
}

.hold {
  position: absolute;
  width: 1050px;
  height: 1050px;
  border-radius: 100%;
  clip: rect(0px, 1050px, 1050px, 525px);
  outline: 40px solid #606;

}



.innerCircle {
  position: absolute;
  width: 615px;
  height: 615px;
  background-color: #FFF;
  /*border: 40px solid #FDD;
  outline: 50px solid #606;*/
  border-radius: 100%;
  top: 220px;
  left: 220px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}

.innerCircle .content {
  position: absolute;
  display: block;
  width: 615px;
  left: 0;
  text-align: center;
  font-size: 14px;
}

.innerCircle h2 {
  color: #FFF;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.innerCircle .aired {
  position: absolute;
  left: 245px;
  top: 35px;
  color: #000;
  text-shadow: -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;
}

.innerCircle .earthed {
  position: absolute;
  left: 510px;
  top: 240px;
}

.innerCircle .burned {
  position: absolute;
  left: 243px;
  top: 500px;
  color: #000;
  text-shadow: -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;
}


.innerCircle .wet {
  position: absolute;
  left: 30px;
  top: 240px;
}

.inner-pie {
  transition: all 1s;
  position: absolute;
  width: 615px;
  height: 615px;
  border-radius: 100%;
  clip: rect(0px, 307.5px, 615px, 0px);
}

.inner-hold {
  position: absolute;
  width: 615px;
  height: 615px;
  border-radius: 100%;
  clip: rect(0px, 615px, 615px, 307.5px);
  transform:rotate(-45deg);
}

.inner-innerCircle {
  position: absolute;
  width: 715px;
  height: 715px;
  background: rgb(58,143,180);
  background: linear-gradient(90deg, rgba(58,143,180,1) 0%, rgba(253,29,29,1) 50%, rgba(135,94,0,1) 100% );
  border-radius: 100%;
  outline: 40px solid #606;
  top: 170px;
  left: 170px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}

.concordantCircle {
  position: absolute;
  width: 655px;
  height: 655px;
  background: #BBB;
  top: 200px;
  left: 200px;
  border-radius: 100%;
  outline: 20px solid #606;
}

.concordantCircle h2 {
  color: #000;
}

.adriftCircle {
  background: #606;
}

.adriftCircle h2 {
  color: #FFF;
  text-shadow: -1px -1px 0 #000A, 1px -1px 0 #000A, -1px 1px 0 #000A, 1px 1px 0 #000A;
}

.inner-inner-innerCircle {
  position: absolute;
  width: 300px;
  height: 300px;
  background: rgb(152,203,0);
  background: linear-gradient(180deg, rgba(152,203,0,1) 0%, rgba(8,8,8,1) 100%);
  outline: 30px solid #FCC;
  border-radius: 100%;
  top: 380px;
  left: 380px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}

.inner-inner-innerCircle h2:first-child {
  color: #FFF;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.inner-inner-innerCircle h2:nth-child(11) {
  color: #FFF;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}


.inner-oval {
  position: absolute;
  width: 300px;
  height: 150px;
  background: #000;
  /*background-image: url(https://i.pinimg.com/originals/9e/52/59/9e52599bdb1118bff89dea54a0ed3eda.png);*/
  background-image: url(https://media.istockphoto.com/photos/deep-space-picture-id472809828?b=1&k=20&m=472809828&s=170667a&w=0&h=goMd2OYeK7iAw-c4FdfaRECqjatgw84mgVTx38sBAtE=);
  background-position: center;
  background-size: 350px;
  background-repeat: no-repeat;
  outline: 10px solid #FCC;
  border-radius: 100%;
  top: 453px;
  left: 380px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}

.sigil {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #FFF;
  outline: 15px solid #000;
  border-radius: 100%;
  top: 478px;
  left: 520px;
  color: white;
}

.sigil h2 {
  color: #000;
  left: -17px;
  position: relative;
  top: -100px;
}

.gate {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #DDD;
  outline: 5px solid #000;
  border-radius: 100%;
  color: white;
}

.gate.beastlands {
  left: 635px;
  top: 235px;
}
.gate.arborea {
  left: 735px;
  top: 305px;
}
.gate.ysgard {
  left: 800px;
  top: 400px;
}

.gate.limbo {
  left: 825px;
  top: 515px;
}

.gate.pandemonium {
  left: 800px;
  top: 630px;
}

.gate.abyss {
  left: 735px;
  top: 740px;
}

.gate.carceri {
  left: 635px;
  top: 800px;
}

.gate.hades {
  left: 520px;
  top: 828px;
}

.gate.gehenna {
  left: 405px;
  top: 804px;
}

.gate.hells {
  left: 305px;
  top: 740px;
}


.gate.acheron {
  left: 235px;
  top: 630px;
}

.gate.bytopia {
  left: 405px;
  top: 235px;
}
.gate.celestia {
  left: 305px;
  top: 305px;
}

.gate.arcadia {
  left: 235px;
  top: 400px;
}

.gate.mechanus {
  left: 215px;
  top: 515px;
}

.gate.elysium {
  left: 520px;
  top: 210px;
}


.sigil-mountain {
  border:solid 5px #000;
  border-color:#000 transparent transparent transparent;
  border-radius: 100%/100px 100px 0 0;
  height: 105px;
  position: relative;
  width: 100px;
}

.sigil-mountain.left {
  left:  -110px;
  top: -135px;
  transform: rotate(100deg);

}

.sigil-mountain.right {
  left: 15px;
  top: -249px;
  transform: rotate(261deg);
}

.inner-oval h2 {
  color: #FFF;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.inner-oval .ether1 {
  top:  -114px;
  left:  90px;
  color: #000;
  position: absolute;
  text-shadow: -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;
}

.inner-oval .ether2 {
  top:  206px;
  left:  90px;
  color: #000;
  position: absolute;
  text-shadow: -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;
}

#inner-pieSlice1 .inner-pie {
  background-color: #A53;
  transform: rotate(90deg);
}

#inner-pieSlice2 .inner-pie {
  background-color: #F00;
  transform: rotate(90deg);
}


#inner-pieSlice3 .inner-pie {
  background-color: #00F;
  transform: rotate(90deg);
}

#inner-pieSlice0 .inner-pie {
  background-color: #9FF;
  transform: rotate(90deg);
}



#inner-pieSlice1 {
 transform: rotate(45deg);
}
#inner-pieSlice2 {
 transform: rotate(135deg);
}
#inner-pieSlice3 {
 transform: rotate(225deg);
}
#inner-pieSlice4 {
 transform: rotate(315deg);
}


.pieSlice1 .pie {
  background-color: #DDD;
  transform: rotate(22.5deg);
}

.pieSlice2 .pie {
  background-color: #CCC;
  transform: rotate(22.5deg);
}


.pieSlice3 .pie {
  background-color: #BBB;
  transform: rotate(22.5deg);
}

.pieSlice4 .pie {
  background-color: #AAA;
  transform: rotate(22.5deg);
}

.pieSlice5 .pie {
  background-color: #999;
  transform: rotate(22.5deg);
}


.pieSlice6 .pie {
  background-color: #888;
  transform: rotate(22.5deg);
}

.pieSlice7 .pie {
  background-color: #777;
  transform: rotate(22.5deg);
}

.pieSlice8 .pie {
  background-color: #888;
  transform: rotate(22.5deg);
}

.pieSlice9 .pie {
  background-color: #999;
  transform: rotate(22.5deg);
}

.pieSlice10 .pie {
  background-color: #AAA;
  transform: rotate(22.5deg);
}

.pieSlice11 .pie {
  background-color: #BBB;
  transform: rotate(22.5deg);
}

.pieSlice12 .pie {
  background-color: #CCC;
  transform: rotate(22.5deg);
}

.pieSlice13 .pie {
  background-color: #DDD;
  transform: rotate(22.5deg);
}

.pieSlice14 .pie {
  background-color: #EEE;
  transform: rotate(22.5deg);
}

.pieSlice15 .pie {
  background-color: #FFF;
  transform: rotate(22.5deg);
}

.pieSlice0 .pie {
  background-color: #EEE;
  transform: rotate(22.5deg);
}


.pieSlice1 {
 transform: rotate(31.75deg);
}
.pieSlice2 {
 transform: rotate(54.25deg);
}
.pieSlice3 {
 transform: rotate(76.75deg);
}
.pieSlice4 {
 transform: rotate(99.25deg);
}
.pieSlice5 {
 transform: rotate(121.75deg);
}
.pieSlice6 {
 transform: rotate(144.25deg);
}
.pieSlice7 {
 transform: rotate(166.75deg);
}
.pieSlice8 {
 transform: rotate(189.25deg);
}
.pieSlice9 {
 transform: rotate(211.75deg);
}
.pieSlice10 {
 transform: rotate(234.25deg);
}
.pieSlice11 {
 transform: rotate(256.75deg);
}
.pieSlice12 {
 transform: rotate(279.25deg);
}
.pieSlice13 {
 transform: rotate(301.75deg);
}
.pieSlice14 {
 transform: rotate(324.25deg);
}
.pieSlice15 {
 transform: rotate(346.75deg);
}
.pieSlice0 {
 transform: rotate(369.25deg);
}

.astral-labels h3 {
  color: #FFF;
  position: absolute;
  text-shadow: -1px -1px 0 #000A, 1px -1px 0 #000A, -1px 1px 0 #000A, 1px 1px 0 #000A;
}

.astal-label-1 {
  left: 797px;
  top: 272px;
}


.astal-label-2 {
  left: 800px;
  top: 1360px;
}


.outer-plane-names h3 {
  color: #000;
  text-shadow: -1px -1px 0 #FFFA, 1px -1px 0 #FFFA, -1px 1px 0 #FFFA, 1px 1px 0 #FFFA;
  position: absolute;
}

.outer-plane-names h3 span {
  font-size: 80%;
}

.beastlands {
  left: 975px;
  top: 375px;
}
.arborea {
  left: 1140px;
  top: 480px;
}
.ysgard {
  left: 1245px;
  top: 620px;
}
h3.limbo {
  left: 1285px;
  text-shadow: -0 -0 0 #000, 0 -0 0 #000, -0 0 0 #000, 0 0 0 #000;
  top: 800px;
}
h3.pandemonium {
  top: 995px;
  left: 1209px;
  color: #FFF;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
h3.abyss {
  color: #FFF;
  left: 1135px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 1135px;
}
h3.carceri {
  color: #FFF;
  left: 995px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 1225px;
}
h3.hades {
  color: #FFF;
  left: 819px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 1265px;
}
h3.gehenna {
  color: #FFF;
  left: 625px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 1225px;
}
h3.hells {
  color: #FFF;
  left: 450px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 1135px;
}
h3.acheron {
  color: #FFF;
  left: 385px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  top: 995px;
}
h3.mechanus {
  top: 800px;
  left: 339px;
  text-shadow: -0 -0 0 #000, 0 -0 0 #000, -0 0 0 #000, 0 0 0 #000;
}
.arcadia {
  top: 620px;
  left: 385px;
}
.celestia {
  top: 480px;
  left: 465px;
}
.bytopia {
  top: 375px;
  left: 635px;
}
.elysium {
  top: 335px;
  left: 815px;
}






/*pyramid*/

.pyramid {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 480px;
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.pyramid_section {
  flex: 1 1 100%;
  background-color: #A7A88A;
  margin-bottom: 2px;
}

.pyramid_section:nth-of-type(even) {
  background-color: #39383B;
  color:  #FFF;
}

.pyramid_section:hover {
  background-color: #0A5786;
  color: #FFF;
}

.pyramid_section h3 {
  padding-top: 1px;
}






/*lavalamp*/


.lamp{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 70vh;
  min-height: 300px;
  width: 50vh;
  min-width: calc(300px * 3 / 7);

}
.glass{
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 10%;
}
.lava{
  filter: url("#goo");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.blob{
  border-radius: 50%;
  background: #e8630a;
  position:absolute;
}

.blob:nth-child(1){
  background: #0FF;
  color: #000;
  width: calc(25% * 1.4);
  height: 25%;
  left: 45%;
  bottom: 0%;

  animation: blob-one ease-in-out 19s infinite;
}
.blob:nth-child(2){
  background: #DD0;
  color: #000;
  width: calc(22% * 1.4);
  height: 22%;
  right: 14%;
  bottom:0%;

  animation: blob-two ease-in-out 21s infinite;
}
.blob:nth-child(3){
  background: #0B0;
  color: #000;
  width: calc(25% * 1.4);
  height: 25%;
  bottom:0%;
  left: 14%;

  animation: blob-three ease-in-out 26s infinite;
}


@keyframes blob-one{
  0%, 100%{
    transform: translatey(50%);
  }
  50%{
    transform: translatey(-200%);
  }
}

@keyframes blob-two{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-420%);
  }
}

@keyframes blob-three{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-305%);
  }
}






.overlay-list {
  background-color: #FFF;
  margin: 4px;
  position: fixed;
  right: 1px;
  top: 1px;
  z-index: 1000;
}

.character-listage .collapseable {
  display: inline-block;
  min-width: 110px;
}

.character-listage .hidden-list {
  background: #39383B;
  color:  #FFF3E7;
  border: 1px solid #000;
  padding: 1px 10px 1px 1px;
  position: absolute;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* RUNES */

.letter-col {
  flex: 1;
}

.rune-col {
  flex: 1;
}

.meaning-col {
  flex: 2;
}

.runebox {
  display: inline-block;
  height: 64px;
  width: 53px;
  overflow: hidden;
}


/*.rune-word .runebox {
  margin-left: -8px;
}
*/







/*  MOBILE STYLING KEEP DOWN  */

@media (max-width: 700px) {
  .reading-portion {
    width: 90%;
  }
  .most-a-panel,
  .info-card,
  .half-panel,
  .info-panel,
  .side-panel,
  .index-listing,
  .random-list
  .nav {
    overflow: hidden;
    padding-left: 0;
    width: 100%;
  }

  .index-listing {
    margin-left: 0
  }

  .nav .links a {
    display: block;
  }

  .nav .links {
    display: none;
  }

  .nav .mobile-header, .mobile-home-link {
    display: block;
  }

  .nav .standard-header {
    display: none !important;
  }

  .nav:hover  .links  {
    display: block;
  }
}
